.table-container {
    /* max-height: 400px; Set a height limit */
    overflow-y: auto;  /* Enable vertical scrolling */
    overflow-x: auto;  /* Enable horizontal scrolling */
}

.table {
    width: 100%; /* Full width for table */
    border-collapse: collapse;
    border: 1px solid #ddd; /* Optional: Table border */
    table-layout: fixed;
}

th, td {
    text-align: center;
    padding: 12px; /* Adjust padding for better spacing */
    border: 1px solid #ddd; /* Border for cells */
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
    width: 200px;

    /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  /* text-overflow: ellipsis; */
  max-height: 50px; /* Set a max height */
  overflow: auto;   /* Adds scroll if content exceeds max height */
}

thead th {
    position: sticky; /* Sticky headers while scrolling */
    top: 0;
    background-color: #5684e4; /* Add header background color */
    color: white; /* Header text color */
    font-weight: bold;
    z-index: 1;
}
.th-color{
    background-color: #5684e4;
    color:#fff;
}
/* td {
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
  } */