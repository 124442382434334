.custom-table {
    border-color: gray;
    margin-bottom: 0;
}

.mt-3 {
    margin-top: 40px;
}

.form-mt-3 {
    margin-top: 40px;
    margin-bottom: 60px;
}

.subhead-mt-3 {
    margin-top: 20px;
}

.parent-mt-3 {
    margin-top: 70px;
}

.orderForm-mt-3 {
    margin-bottom: 40px;
}

.add_new_user h4 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.card.p-3 {
    margin-bottom: 20px;
}

.bold {
    font-weight: bold;
}

.ml-2 {
    margin-left: 0.5rem;
}

.button-margin-right {
    margin-right: 10px;
    text-align: right;
}