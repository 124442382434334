.main_page{
  background-color: #F2F4F8;
}

*{
  font-family: 'Inter', sans-serif;
}

label,p,span,h1,h3{
  font-family: "Inter" !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

