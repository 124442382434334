/* src/components/CustomTable.css */
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.table th,
.table td {
    border: 1px solid #ddd;
    /* padding: 8px; */
    text-align: center;
    vertical-align: middle;
}

.table th {
    background-color: #f2f2f2;
    cursor: pointer;
    position: relative;
}

.table th:hover {
    background-color: #e0e0e0;
    /* Change background color on hover */
}

.sort-arrow {
    visibility: hidden;
    margin-left: 8px;
    transition: visibility 0.2s;
}

.table th:hover .sort-arrow {
    visibility: visible;
}

.sort-arrow.ascending {
    color: #007bff;
}

.sort-arrow.descending {
    color: #007bff;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 10px;
    list-style: none;
}

.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.items-per-page {
    margin-right: 10px;
}

.pagination-info {
    display: flex;
    align-items: center;
}

.pagination__link {
    background: none;
    border: 1px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 5px;
    color: #007bff;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s;
}

.pagination__link:hover {
    background-color: #007bff;
    color: white;
}

.pagination__link:disabled {
    color: #ccc;
    cursor: not-allowed;
    border-color: #ccc;
}

table td button {
    display: inline-block;
    margin: 0 5px;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.actions-cell {
    display: flex;
    justify-content: center; 
    align-items: center;
    gap: 10px;
}
.table td.actions-cell {
    border: none;
}